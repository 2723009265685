.wrapper {
  width: 100%;
  text-align: center;
}

.resultsTitle {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #333;
}

.container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of product containers */
  width: 100%;
  justify-content: center; /* Center items horizontally */
  min-height: 250px; /* Ensure the container maintains a minimum height */
}

.product {
  flex: 0 1 250px; /* Set the product container width to 250px */
  margin: 10px;
  padding: 20px; /* Reduced padding */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-width: 300px; /* Set a fixed width for the product containers */
  text-align: center;
  position: relative;
  top: -5px;
  overflow: hidden; /* Ensure the product container hides overflow */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the "View Product" button is at the bottom */
}

.image {
  width: auto; /* Change from 100% to auto to prevent stretching */
  height: auto; /* Maintain aspect ratio */
  max-width: 200px; /* Limit the maximum width */
  max-height: 150px; /* Reduce maximum height from 200px to 150px */
  border-radius: 4px;
  object-fit: contain; /* Ensure the image is contained within the container without stretching */
  margin: 0 auto; /* Center the image horizontally */
}

.no-image-placeholder {
  width: 200px;
  height: 150px;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.9rem;
  margin: 0 auto 10px auto;
}

.price {
  font-weight: bold;
  margin: 10px 0;
}

.vendorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.vendor {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.stockLabel {
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.85em;
  font-weight: 500;
}

.stockLabel.inStock {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.stockLabel.outOfStock {
  background-color: #ffebee;
  color: #c62828;
}

.link {
  display: inline-block;
  padding: 10px 30px;
  background-color: white; /* White background */
  color: #5a9bf6; /* Slightly lighter blue text */
  text-decoration: none;
  border-radius: 4px;
  border: 2px solid #5a9bf6; /* Slightly lighter blue outline */
  margin-top: auto; /* Push the button to the bottom */
  width: fit-content; /* Make the button not full width */
  align-self: center; /* Center the button horizontally */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  cursor: pointer;
}

.link:hover {
  background-color: #5a9bf6; /* Slightly lighter blue background on hover */
  color: white; /* White text on hover */
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px; /* Ensure the empty state container has a height */
  color: #999;
  font-size: 1rem;
}

.topChoiceTag {
  position: absolute;
  top: 10px;
  right: 10px; /* Move to the top right of the container */
  background-color: #5a9bf6; /* Slightly lighter blue background for top choice */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  z-index: 10; /* Ensure the tag is above other elements */
}

.summary {
  text-align: left; /* Align text to the left */
}

.googleShoppingLink {
  display: inline-block;
  padding: 10px 20px;
  color: #5a9bf6; /* Blue text */
  text-decoration: none;
  margin: 5px 0; /* Small margin for spacing */
  padding-top: 5px; /* Add appropriate top padding */
  cursor: pointer;
  font-size: 12px;
  width: fit-content; /* Make the link not span full width */
  align-self: center; /* Center the link horizontally */
}

.searchQuery {
  font-size: 0.95rem; /* Slightly larger font size */
  color: #666; /* Darker color for better readability */
  margin: -5px 0 20px 0; /* Space below the text */
  text-align: center; /* Center the text */
}

.loadingSummaries {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center horizontally */
  width: 100%;
  height: auto;
  color: #5a9bf6; /* Match the blue color */
  font-size: 0.9rem;
  padding: 10px 0;
  text-align: center;
  z-index: 1000; /* Ensure it is above other elements */
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  border-radius: 4px;
  margin-bottom: 10px;
}

.loadingSummaries .spinner {
  border: 3px solid rgba(0, 0, 0, 0.1); /* Make the border thicker */
  border-left-color: #5a9bf6; /* Blue color for the spinner */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  margin-right: 10px; /* Add some space between spinner and text */
  position: relative;
  top: 0;
}

.summary-loading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0;
  background-color: transparent;
  margin: 5px 0;
  border: none;
}

.summary-loading span {
  color: #1976d2; /* Match datasheetLink color */
  font-size: 0.9em;
  text-decoration: none;
}

.spinner.small {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4d8df5; /* Same blue color as other elements in the app */
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.improveResultsButton {
  background-color: #5a9bf6; /* Blue background color */
  color: white; /* White text color */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Add padding for better spacing */
  font-size: 1rem; /* Set font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin: 10px 0; /* Add margin for spacing */
}

.improveResultsButton:hover {
  background-color: #4a8be6; /* Darker blue on hover */
}

.improveResultsButton:disabled {
  background-color: #cccccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor when disabled */
}

.deepSearchLink {
  color: #666; /* Gray color */
  text-decoration: underline; /* Underlined text */
  cursor: pointer;
}

.manufacturer,
.partNumber,
.stockStatus {
  margin: 8px 0;
  font-size: 0.9em;
  color: #333;
}

.stockStatus.inStock {
  color: #2e7d32;
}

.stockStatus.outOfStock {
  color: #c62828;
}

/* Description styling */
.product-description {
  margin: 0 0 12px 0;
  font-size: 0.9em;
  line-height: 1.4;
  color: #444;
  text-align: center;
}

/* Show More dropdown styling */
.showMore {
  margin: 12px 0;
  width: 100%;
}

.showMore summary {
  cursor: pointer;
  color: #1976d2;
  font-size: 0.95em;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.2s;
}

.showMore summary:hover {
  background-color: #e3f2fd;
}

.additionalInfo {
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 0 0 4px 4px;
  margin-top: 4px;
}

.additionalInfo p {
  font-size: 1.1em;
  margin: 12px 0;
  text-align: left;
}

/* Update existing styles */
.datasheetLink {
  display: inline-block;
  margin: 0 0 8px 0;
  color: #1976d2;
  text-decoration: none;
  font-size: 0.9em;
}

.datasheetLink.withPadding {
  margin-bottom: 15px;
}

/* Remove styles we don't need anymore */
.detailedDescription {
  display: none;
}

/* Datasheet Summary Styles */
.datasheet-section {
  margin: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.datasheet-summary {
  margin: 0;
  padding: 0 12px;
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  text-align: left;
  border: none;
  color: #333;
}

.datasheet-summary h5 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 0.95em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.summary-content {
  font-size: 1em;
  line-height: 1.4;
}

.summary-content p {
  margin: 8px 0;
}

/* Style for the batch processing banner */
.auto-summarize-banner {
  background-color: #f0f7ff;
  border: 1px solid #d0e3ff;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a7fd6;
  font-size: 0.9rem;
}

.auto-summarize-banner p {
  margin: 0;
}
/* Auto Summarize Banner */
.auto-summarize-banner {
  background-color: rgba(90, 155, 246, 0.1);
  border: 1px solid rgba(90, 155, 246, 0.3);
  border-radius: 8px;
  padding: 10px;
  margin: 0 0 20px 0;
  width: 100%;
  max-width: 800px;
  text-align: center;
  animation: pulse 2s infinite ease-in-out;
}

.auto-summarize-banner p {
  color: #5a9bf6;
  font-size: 0.9rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-summarize-banner p::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5a9bf6;
  margin-right: 8px;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.datasheet-summary.error {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff0f0;
  border: 1px solid #ffcccc;
  border-radius: 4px;
  font-style: italic;
  color: #d32f2f;
  font-size: 0.9rem;
}

.datasheet-summary.error p {
  margin: 0;
}

.datasheet-summary.plain {
  margin: 0;
  padding: 0 15px 15px 15px;
  background-color: transparent;
  border-radius: 4px;
  text-align: left;
  white-space: pre-wrap;
  font-size: 0.95em;
  line-height: 1.3;
  border: none;
}

/* Add styles for bullet points in plain format */
.datasheet-summary.plain p {
  margin: 0 0 2px 0;
  padding-left: 16px;
  position: relative;
}

.datasheet-summary.plain p::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 1.1em;
}

.datasheet-summary.plain li {
  margin: 0 0 2px 0;
  padding-left: 16px;
  position: relative;
  display: block;
}

.datasheet-summary.plain li::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 1.1em;
}

.datasheet-summary.plain h1,
.datasheet-summary.plain h2,
.datasheet-summary.plain h3 {
  margin: 5px 0 3px 0;
  padding-left: 0;
}

.datasheet-summary.plain h1::before,
.datasheet-summary.plain h2::before,
.datasheet-summary.plain h3::before {
  display: none;
}

.datasheet-summary.markdown {
  margin: 0;
  padding: 0 10px;
  background-color: transparent;
  border-radius: 4px;
  text-align: left;
  font-size: 1em;
  line-height: 1.3;
  color: #333;
  border: none;
}

/* Tighten up spacing in markdown */
.datasheet-summary.markdown p {
  margin: 0 0 2px 0;
  padding-left: 16px;
  position: relative;
}

.datasheet-summary.markdown p::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 1.1em;
}

.datasheet-summary.markdown li {
  margin: 0 0 2px 0;
  padding-left: 16px;
  position: relative;
}

.datasheet-summary.markdown li::before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 1.1em;
}

/* Remove spacing from headings */
.datasheet-summary.markdown h1,
.datasheet-summary.markdown h2,
.datasheet-summary.markdown h3 {
  margin: 8px 0 4px 0;
  font-weight: 600;
  line-height: 1.2;
  padding-left: 0;
}

.datasheet-summary.markdown h1::before,
.datasheet-summary.markdown h2::before,
.datasheet-summary.markdown h3::before {
  display: none;
}

/* Compact lists with minimal spacing */
.datasheet-summary.markdown ul,
.datasheet-summary.markdown ol {
  padding-left: 0;
  margin: 4px 0;
  list-style-type: none;
}

/* Style for failed datasheet summaries */
.summary-failed {
  padding: 12px 16px;
  background-color: #fff4f4;
  border: 1px solid #ffd0d0;
  border-radius: 4px;
  margin: 10px 0;
  color: #d85656;
  font-size: 0.9rem;
  text-align: center;
}

.summary-failed button {
  background-color: #fff;
  border: 1px solid #d85656;
  color: #d85656;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}

.summary-failed button:hover {
  background-color: #d85656;
  color: white;
}
