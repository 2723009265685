.filters-container {
  background-color: white;
  width: 100%;
  box-sizing: border-box;
}

.filters-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
}

.filters-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filters-header-left h2 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.filters-header-actions {
  display: flex;
  gap: 10px;
}

.filters-header-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  height: 36px;
}

.filters-header-actions button:first-child {  /* Clear All button */
  background-color: white;
  border: 1px solid #ccc;
  color: #666;
}

.filters-header-actions button:first-child:hover {
  background-color: #f5f5f5;
  color: #333;
}

.filters-header-actions button:nth-child(2) {  /* Apply Filters button */
  background-color: #ffffff;
  border: 2px solid #4d8df5;
  color: #4d8df5;
}

.filters-header-actions button:nth-child(2):hover {
  background-color: #4d8df5;
  color: #ffffff;
}

.filters-header-actions .deep-search-button {
  background-color: #4d8df5 !important;
  border: none !important;
  color: #ffffff !important;
}

.filters-scroll-container {
  overflow-x: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.filters-row {
  display: flex;
  gap: 15px;
  min-width: min-content;
}

.filter-group {
  min-width: 325px;
  max-width: 325px;
  width: 325px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: 0;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 28px;
}

.filter-header h3 {
  margin: 0;
  font-size: 1rem;
  color: #333;
  flex: 1;
  margin-right: 10px;
}

.selected-count {
  font-size: 0.9em;
  color: #666;
  margin-right: 10px;
  min-width: 70px;
  text-align: right;
}

.filter-options {
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  box-sizing: border-box;
}

.filter-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-bottom: 1px solid #eee;
  font-size: 0.9em;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  align-items: center;
}

.filter-option:last-child {
  border-bottom: none;
}

.filter-option:hover {
  background-color: #f5f5f5;
}

.filter-option.selected {
  background-color: #e3ecfd;
  color: #4d8df5;
  font-weight: 500;
  border-bottom: 1px solid #eee;
}

.clear-filter {
  padding: 4px 8px;
  font-size: 0.8em;
  color: #666;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  min-width: 45px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease;
  margin-right: 5px;
}

.clear-filter:hover {
  background-color: #f5f5f5;
  color: #333;
}

.select-wrapper {
  position: relative;
}

.select-wrapper select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  min-height: 150px;
}

.select-wrapper select option {
  padding: 6px 8px;
  line-height: 1.4;
}

.select-wrapper select option:checked {
  background-color: #5693f5;
  color: white;
}

.select-help {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  text-align: right;
}

.filters-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0;
  margin-top: 0;
}

.filters-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.filters-actions button:first-child {
  background-color: white;
  border: 1px solid #ccc;
  color: #666;
}

.filters-actions button:first-child:hover {
  background-color: #f5f5f5;
  color: #333;
}

.filters-actions button:nth-child(2) {
  background-color: #5693f5;
  color: white;
}

.deep-search-button {
  background-color: #ffffff !important;
  border: 2px solid #4d8df5 !important;
  color: #4d8df5 !important;
}

.deep-search-button:hover {
  background-color: #4d8df5 !important;
  color: #ffffff !important;
}

.search-input-container {
  position: relative;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.2s ease, opacity 0.2s ease;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.9em;
}

.filter-search-input {
  flex: 1;
  padding: 6px 10px 6px 32px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 0.9em;
  box-sizing: border-box;
  outline: none;
}

.filter-search-input:focus {
  border-color: #4d8df5;
}

.filter-search-input::placeholder {
  color: #999;
}

.search-button {
  padding: 6px 12px;
  background-color: #4d8df5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.filter-search-toggle {
  padding: 4px 8px;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  margin-left: auto;
}

.filter-search-toggle:hover {
  background-color: #f5f5f5;
  color: #333;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 15px;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
}

.back-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

/* Add new class for when search is active */
.filter-group.search-active .search-input-container {
  height: 34px; /* Height of the search input + padding */
  opacity: 1;
  overflow: visible;
}

.category-separator {
  font-size: 0.8em;
  color: #666;
  margin: 0 8px;
  font-weight: normal;
}

.requirements-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.requirements-modal {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 500px;
  max-width: 90vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.requirements-modal h2 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 1.2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.requirements-modal textarea {
  width: 100%;
  min-height: 100px;
  height: 100px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  resize: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.requirements-modal button {
  background-color: #4d8df5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.requirements-modal button:hover {
  background-color: #3b7de5;
} 