.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4d8df5;
  color: white;
  padding: 20px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.right-section {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.logo {
  font-size: 1.5rem;
  margin: 0;
  color: white;
}

.page-link {
  color: white;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none;
}

.page-link.active {
  text-decoration: underline;
}

.alpha-signup-link {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  font-size: 0.95rem;
  transition: opacity 0.3s;
}

.feedback-button {
  background-color: white;
  color: #4d8df5;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.feedback-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.new-search-button {
  background-color: white;
  color: #4d8df5;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.new-search-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.session-id:hover {
  color: #f0f0f0;
}
