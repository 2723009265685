.search-form-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.search-title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.search-subtitle {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 400;
}

.main-search {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.main-search-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.main-search-input:disabled {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.additional-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.input-helper-text {
  width: 100%;
  margin: 0 0 15px 0;
  color: #666;
  font-size: 0.9rem;
  text-align: center;
  font-style: italic;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-group label {
  font-weight: 500;
  min-width: 100px;
}

.additional-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100px;
}

.button-group {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.submit-button, .reset-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.submit-button {
  background-color: #28a745;
  color: white;
}

.reset-button {
  background-color: #dc3545;
  color: white;
}

.submit-button:hover {
  background-color: #218838;
}

.reset-button:hover {
  background-color: #c82333;
}

/* New confirmation section styles */
.confirmation-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirmation-section h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 1.5rem;
}

.search-summary {
  background-color: white;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 25px;
  text-align: left;
}

.summary-item {
  margin: 10px 0;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.summary-item:last-child {
  border-bottom: none;
}

.summary-item strong {
  color: #555;
  margin-right: 10px;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.confirm-button, .cancel-button {
  padding: 12px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.confirm-button {
  background-color: #28a745;
  color: white;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.confirm-button:hover {
  background-color: #218838;
}

.cancel-button:hover {
  background-color: #5a6268;
} 