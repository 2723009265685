.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background-color: #ffffff;
  padding: 0; /* Removed padding */
  border-radius: 8px;
  width: 100%;
  max-width: 800px; /* Increased maxWidth */
  box-sizing: border-box;
  margin-top: -30px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; /* Added position relative to position suggestions below input */
}

.input {
  padding: 10px; /* Adjusted padding */
  margin-top: 5px;
  width: calc(100% - 20px); /* Adjusted width to match textarea */
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none; /* Disable manual resizing */
  height: auto; /* Allow automatic height adjustment */
  max-height: 200px; /* Set a maximum height after which it scrolls */
  overflow-y: auto; /* Enable vertical scrolling */
  font-family: Arial, sans-serif; /* Corrected font */
  color: #333; /* Changed to dark grey */
  font-size: 0.9em;
}

.input::placeholder {
  color: #aaa;
}

.button {
  padding: 8px 15px;
  background-color: #5693f5; /* Changed to match the new blue */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
}

.label {
  font-weight: bold;
  color: #333; /* Changed to dark grey */
}

.form-description {
  color: #333; /* Changed to dark grey */
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: left; /* Add text-align left */
  width: 100%; /* Ensure full width */
}

.icon {
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.suggestionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  width: calc(100% - 2px);  /* Account for borders */
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 100%;  /* Position right below the input */
  left: 0;
}

.suggestionItem {
  padding: 10px 12px;
  cursor: pointer;
  font-size: 14px;  /* Base font size */
  line-height: 1.4;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Style for the separator */
.suggestionItem span.separator {
  font-size: 11px;  /* 3px smaller than parent */
  color: #666;
  display: inline-block;
  margin: 0 2px;
  line-height: 1;
}

.suggestionItem:hover {
  background-color: #f5f5f5;
}

.suggestionItem:last-child {
  border-bottom: none;
}

.response-container {
  margin-top: 15px;
  background-color: #f4f4f9;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 0.95em;
  width: 100%; /* Make the response container full width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
}

.siteButtons {
  margin-top: -15px;
  margin-bottom: -10px;
}

.siteButtons button {
  border: 2px solid #4d8df5; /* Blue outline */
  color: #4d8df5; /* Blue text */
  background-color: #fff; /* White fill */
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px; /* Right margin */
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px;
}

.siteButtons button:hover {
  background-color: #4d8df5; /* Blue background on hover */
  color: #fff; /* White text on hover */
}

.siteButtons div {
  font-size: 0.9em; /* Make the title smaller */
  margin: 10px 0; /* Add some space below the title */
  color: #333;
}

.siteButtons button.active {
  background-color: #4d8df5; /* Lighter blue background for active state */
  color: #fff; /* White text for active state */
}

.search-duration {
  font-size: 0.95em; /* Slightly smaller font size */
  color: #666; /* Medium grey color */
  margin: 20px 0 -5px 0
}

.advanced {
  margin-top: 20px;
  border-radius: 4px;
  width: 100%; /* Make the advanced section full width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.advanced-toggle {
  display: inline-flex; /* Change from flex to inline-flex to prevent stretching */
  align-items: center;
  cursor: pointer;
  color: #777; /* Dark grey text */
  font-size: 0.9em; /* Normal font size */
  transition: color 0.3s ease;
  margin-top: -5px;
}

.advanced-toggle .icon {
  margin-right: 5px; /* Space between icon and text */
}

.defaultSpecifications {
  margin-top: 15px;
}

.defaultSpecifications .label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em; /* Slightly smaller font size */
  color: #333; /* Dark grey color */
}

.defaultSpecifications .input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px;
  font-size: 0.9em; /* Slightly smaller font size */
  color: #333; /* Dark grey color */
  transition: border-color 0.3s ease;
}

.defaultSpecifications .input:focus {
  border-color: #4d8df5; /* Blue border on focus */
  outline: none; /* Remove default outline */
}

.deepSearchButton {
  background-color: #ffffff;
  border: 2px solid #4d8df5;
  color: #4d8df5;
}

.deepSearchButton:hover {
  background-color: #4d8df5;
  color: #ffffff;
}

.tabs {
  display: flex;
  justify-content: flex-end; /* Align tabs to the right */
  margin-bottom: 20px;
}

.tab {
  padding: 5px 0; /* Reduced padding */
  cursor: pointer;
  background-color: #fff;
  color: #4d8df5;
  transition: color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-size: 0.95em; /* Smaller font size */
}

.tab.active {
  text-decoration: underline; /* Underline when active */
}

.tab:hover {
  text-decoration: underline; /* Underline on hover */
}

.productFormContainer {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  padding: 30px 30px 30px 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* When filters are showing */
.productFormContainer.filters-active {
  width: 90%;
  max-width: none;
  margin-bottom: 10px;
}

/* When results are showing without filters */
.productFormContainer.results-active {
  width: 90%;
  max-width: none;
  margin-bottom: 10px;
  padding: 15px 30px; /* Reduced padding for just the header */
  border-bottom: 1px solid #eee; /* Add a subtle bottom border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Lighter shadow for just the header */
}

/* Filters header styling (used also for results header) */
.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0; /* Remove bottom margin when used in results view */
  width: 100%;
  height: 44px; /* Fixed height to prevent size changes */
  box-sizing: border-box; /* Ensure padding is included in the height */
}

.filters-header-left {
  display: flex;
  align-items: center;
}

.filters-header h2 {
  margin: 0 0 0 10px;
  font-size: 1.2rem;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px; /* Limit width to prevent overflow */
}

.back-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.back-button svg {
  margin-right: 6px;
}

.customModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.customModal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 450px;
  max-height: 80vh;
  box-sizing: border-box;
  overflow: hidden;
}

.customModal h2 {
  margin: 0;
  padding: 15px 0;
  color: #333;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.customModal .fieldsContainer {
  overflow-y: auto;
  flex-grow: 1;
  padding: 0 0 10px 0;
}

.customModal label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #333;
}

.customModal input {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  color: #333;
  margin-bottom: 12px;
}

.customModal button {
  display: block;
  margin: 10px auto 0 auto;
}

.customModal select {
  background-color: transparent; /* Remove background color */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.9em;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}

.customModal .fieldsContainer > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.customModal .closeButton {
  position: absolute;
  top: -7px;
  right: 10px;
  font-size: 2em;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 20;
}

.datasheetLink {
  color: #4d8df5; /* Blue text */
  text-decoration: none; /* Remove underline */
  font-size: 1em; /* Match font size */
  transition: color 0.3s ease;
  margin-top: -5px; /* Add some space above */
  display: inline-block; /* Align with other elements */
}

.datasheetLink:hover {
  text-decoration: underline; /* Underline on hover */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.filter-section {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.filter-section h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2rem;
}

.filter-group {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.filter-group h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1rem;
}

.filter-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
  margin: 0;
  margin-bottom: 0;
  min-height: 44px;
  height: 44px; /* Add fixed height to prevent size changes */
  box-sizing: border-box; /* Ensure padding is included in the height */
}

/* Make sure there's no bottom margin on any filter header elements */
.filter-header h3, 
.filter-header .selected-count {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-header h3 {
  flex-shrink: 0;
  margin-right: 8px;
  max-width: 140px;
  margin-right: auto;
}

.selected-count {
  font-size: 0.8rem;
  color: #4d8df5;
  padding: 2px 10px;
  border-radius: 12px;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative; /* Add position relative */
  display: none; /* Hide the selected count */
  align-items: center; /* Center content vertically */
  height: 22px; /* Fixed height to prevent size changes */
}

.clear-filter {
  padding: 4px 8px;
  font-size: 0.8em;
  color: #666;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.clear-filter:hover {
  background-color: #f5f5f5;
  color: #333;
}

.select-wrapper {
  position: relative;
}

.select-help {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  text-align: right;
}

.filter-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  min-height: 150px;  /* Increased height to show more options */
}

.filter-group select option {
  padding: 6px 8px;
  line-height: 1.4;
}

.filter-group select option:checked {
  background-color: #5693f5;
  color: white;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.filter-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.filter-actions button:first-child {  /* Clear All button */
  background-color: white;
  border: 1px solid #ccc;
  color: #666;
}

.filter-actions button:first-child:hover {
  background-color: #f5f5f5;
  color: #333;
}

.filter-actions button:last-child {  /* Apply Filters button */
  background-color: #5693f5;
  color: white;
}

.filter-actions button:last-child:hover {
  background-color: #4a7fd6;
}

/* Category Options Styles */
.categoryOptionsContainer {
  margin-bottom: 5px;
  width: 100%;
}

.categoryOptionsContainer h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-top: 15px;
}

.categoryOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.categoryOption {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  transition: border-color 0.2s ease;
}

.categoryOption:hover {
  border-color: #4d8df5;
}

.categoryImageContainer {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 8px;
  overflow: hidden;
}

.categoryImage {
  max-width: 100%;
  max-height: 110px;
  object-fit: contain;
}

.categoryName {
  padding: 10px;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  color: #333;
  background-color: #fafafa;
  border-top: 1px solid #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-image-placeholder-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px; /* Match the height of the categoryImageContainer minus padding */
  width: 100%;
  background-color: #f5f5f5;
  color: #999;
  font-size: 14px;
  border-radius: 2px; /* Add slight rounding */
}

/* Mobile responsiveness for category options */
@media (max-width: 768px) {
  .categoryOptions {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .categoryOptions {
    grid-template-columns: 1fr;
  }
}

/* Workflow Modal Styles */
.workflow-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.workflow-modal {
  background-color: white;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.workflow-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px; /* Increased padding */
  border-bottom: 1px solid #eee;
}

.workflow-modal-header h2 {
  margin: 0;
  font-size: 1.3rem; /* Slightly smaller title */
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.workflow-modal-content {
  padding: 20px 24px;
  overflow-y: auto;
  max-height: calc(90vh - 136px); /* Account for header and footer */
}

.workflow-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  border-top: 1px solid #eee;
}

/* Category Grid Styling */
.modal-categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
  position: relative;
  min-height: 150px; /* Ensure minimum height for spinner */
}

.modal-categories-grid .centered-spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1; /* Span all columns */
  width: 100%;
  height: 100%;
  min-height: 150px;
}

.centered-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
}

.modal-category-option {
  border: 1px solid #ddd; /* Reduced from 2px to 1px */
  border-radius: 6px;
  overflow: hidden;
  transition: border-color 0.2s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 180px;
  box-sizing: border-box; /* Add box-sizing to include border in dimensions */
}

.modal-category-option:hover {
  border: 1px solid #4d8df5; /* Reduced from 2px to 1px */
}

.modal-category-option.selected {
  border: 1px solid #4d8df5; /* Reduced from 2px to 1px */
  box-shadow: 0 0 0 1px rgba(77, 141, 245, 0.5);
}

.modal-category-image-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 8px;
}

.modal-category-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.no-image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #999;
  font-size: 0.9rem;
  text-align: center;
}

.modal-category-name {
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
  background-color: white;
  border-top: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Filter Styling - 2 per row */
.modal-filters-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 filters per row */
  gap: 16px;
  margin-top: 16px;
}

.modal-filter-group {
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
}

.modal-filter-group .filter-header {
  margin-bottom: 0; /* Ensure no bottom margin in modal filter headers */
  padding: 8px 16px; /* Reduced top/bottom padding from 10px to 8px */
  height: 44px; /* Ensure consistent height */
}

.modal-filter-group .filter-options {
  margin: 0; /* Ensure no margin in filter options container */
}

.modal-filter-group .filter-option {
  padding: 16px 16px; /* Match padding with general filter-option */
  line-height: 1.3; /* Standardized line height */
  height: 50px; /* Increased fixed height from 44px to 50px */
  display: flex;
  align-items: center; /* Center content vertically */
}

.filter-search-toggle {
  background: none;
  border: 1px solid #ddd;
  font-size: 0.95rem; /* Slightly smaller icon font size */
  cursor: pointer;
  color: #777;
  padding: 6px 8px;
  z-index: 2;
  border-radius: 4px;
  margin-left: auto;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  flex-shrink: 0;
}

.filter-search-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  border-color: #aaa;
}

/* Make search container more visible with stronger styles */
.search-input-container {
  position: relative;
  padding: 10px 16px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Improve search input styling */
.filter-search-input {
  width: 100%;
  padding: 8px 8px 8px 30px;
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 0.9rem;
  height: 36px;
  box-sizing: border-box;
}

/* Ensure search icon is properly positioned */
.search-icon {
  position: absolute;
  left: 24px;
  color: #777;
  font-size: 0.9rem;
  z-index: 6;
  pointer-events: none;
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 0; /* Remove gap between options */
  padding: 0; /* Remove padding */
  max-height: 250px;
  overflow-y: auto;
  margin-top: 0; /* Ensure no gap between header and first item */
}

.filter-option {
  font-size: 0.9rem;
  padding: 16px 16px; /* Increased vertical padding from 14px to 16px */
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.3; /* Standardized line height */
  height: 50px; /* Increased fixed height from 44px to 50px */
  display: flex;
  align-items: center; /* Center content vertically */
}

.filter-option:last-child {
  border-bottom: none; /* Remove border from last item */
}

.filter-option:hover {
  background-color: #f5f5f5;
}

.filter-option.selected {
  background-color: #4d8df5;
  color: white;
  border-color: #4d8df5;
}

/* Centered Spinner */
.centered-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}

.centered-spinner {
  border: 6px solid rgba(0, 0, 0, 0.1); /* Increased from 4px to 6px for thicker spinner */
  border-radius: 50%;
  border-top: 6px solid #4d8df5; /* Increased from 4px to 6px for thicker spinner */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Final Query Input - Consistent with app styling */
.final-query {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

.final-query p {
  margin-bottom: 16px;
  font-size: 1rem;
  color: #333;
}

.final-query-input {
  width: 100%;
  padding: 12px; /* Reduced from 16px to 12px */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: Arial, sans-serif;
  color: #333;
  resize: none;
  margin-bottom: 16px;
  box-sizing: border-box;
  min-height: 100px;
}

/* Button Styles - Updated to be consistent with app */
.workflow-modal-footer .button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.95rem;
  height: 36px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.workflow-modal-footer .button.primary {
  background-color: #4d8df5;
  color: white;
  border: none;
  font-weight: 500;
}

.workflow-modal-footer .button.primary:hover {
  background-color: #3a7ce0;
}

.workflow-modal-footer .button.secondary {
  background-color: #fff;
  color: #555;
  border: 1px solid #ccc;
  font-weight: normal;
}

.workflow-modal-footer .button.secondary:hover {
  background-color: #f5f5f5;
}

/* Inline search in filter header */
.search-input-container-inline {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 8px;
  flex: 1;
  max-width: 300px;
  position: relative;
  margin-left: 10px;
  height: 28px; /* Add fixed height to prevent size changes */
}

.filter-search-input-inline {
  border: none;
  padding: 6px 6px 6px 24px; /* Reduced left padding from 30px to 24px */
  width: 100%;
  font-size: 0.85rem;
  outline: none;
  height: 24px;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding is included in the height */
}

.search-icon-inline {
  position: absolute;
  left: 8px; /* Reduced from 10px to 8px */
  color: #777;
  font-size: 0.85rem; /* Reduced from 0.9rem */
}

.filter-search-close {
  background: none;
  border: none;
  color: #777;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem; /* Increased from 0.8rem */
  padding: 0;
  margin-left: 4px;
}

.filter-search-close:hover {
  color: #333;
}

/* Make sure the filter header items maintain consistency regardless of search state */
.filter-header > * {
  /* Add this to ensure all direct children of filter-header maintain sizing */
  flex-shrink: 0;
}
